import React, { useState, useContext, useEffect } from "react"
import * as styles from "components/member/Setting/account.module.css"
import Frame from "./frame"
import * as styles2 from "components/tools/mobileCheck.module.css"
import * as styles3 from "components/member/Setting/subscriber.module.css"
import DaumPostcode from "react-daum-postcode"
import BlankModal from "components/tools/modalComponents/blankModal.js"
import modalContext from "context/modalContext"
import api from "apis"
import { navigate } from "gatsby"

const Subscriber = props => {
  const [active, setActive] = useState({
    name: false,
    phone: false,
    address: false,
  })
  const { setmodalOpen, setModalMain } = useContext(modalContext)

  const [address, setAddress] = useState("") // 주소
  const [zipCode, setZipCode] = useState("") // 지번

  const [account, setAccount] = useState(props.subscribe)

  const onCompletePost = data => {
    document.getElementById("address").value = data.address

    console.log(data.zonecode)
    console.log(data.address)

    setZipCode(data.zonecode)
    setAddress(data.address)
    setmodalOpen(false)
    navigate(-1)
  }

  useEffect(() => {
    ;(async () => {
      const post = await api.setting("subscriber")
      setAccount(post.subscribe?.SubscribeProfile)
    })()
  }, [active])

  const dropBoxPw = event => {
    document.getElementById("phoneButton").innerHTML = event.target.id
  }

  const changeName = async () => {
    const name = document.querySelectorAll("input[name=name]")[0].value

    if (name) {
      const post = await api.reviseSetting(
        "reviseSubscribername",

        {
          body: { subscribe_name: name },
        }
      )

      if (post.desc == "success") {
        setActive({ ...active, ["name"]: false })
      }
    } else {
      setActive({ ...active, ["name"]: false })
    }
  }

  const changePhone = async () => {
    const phoneNum1 = document.getElementById("phoneButton").innerHTML
    const phoneNum2 = document.querySelectorAll("input[name=phonenumber2]")[0]
      .value
    const phoneNum3 = document.querySelectorAll("input[name=phonenumber3]")[0]
      .value

    if (phoneNum1 && phoneNum2 && phoneNum3) {
      const post = await api.reviseSetting("reviseSubscriberphone", {
        body: {
          subscribe_phone: phoneNum1 + phoneNum2 + phoneNum3,
        },
      })
      // console.log(post.desc)
      if (post.desc == "success") {
        setActive({ ...active, ["phone"]: false })
      }
    } else {
      setActive({ ...active, ["phone"]: false })
    }
  }

  const changeAddress = async () => {
    const specific = document.querySelectorAll("input[name=specificAddress]")[0]
      .value

    if (address) {
      const post = await api.reviseSetting(
        "reviseSubscriberaddress",

        {
          body: {
            subscribe_address: address + specific,
          },
        }
      )

      // console.log(post.desc)
      if (post.desc == "success") {
        setActive({ ...active, ["address"]: false })
      }
    } else {
      setActive({ ...active, ["address"]: false })
    }
  }

  const postCodeStyle = {
    width: "60vw",
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "0",
    left: "0",
    margin: "auto",
  }

  const onChangeOpenPost = () => {
    setmodalOpen(true)
    setModalMain(
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, 0)",
        }}
        onClick={() => setmodalOpen(false)}
      >
        <BlankModal
          close={() => {
            setmodalOpen(false)
          }}
          size={{
            w: 450,
            h: 700,
          }}
          title={""}
        >
          <div>
            <DaumPostcode
              style={postCodeStyle}
              autoClose
              onComplete={onCompletePost}
            />
          </div>
        </BlankModal>
      </div>
    )
  }

  const onCancel = target => {
    setActive({ ...active, [target]: false })
  }

  return (
    <>
      <Frame title="구독자 정보">
        <div className={styles.con}>
          <div>
            <div>이름</div>
            <div>
              {active.name === false ? (
                <>
                  <div>{account?.name}</div>
                  <p onClick={() => setActive({ ...active, ["name"]: true })}>
                    변경하기
                  </p>
                </>
              ) : (
                <>
                  <div className={`${styles2.box} inputContainer`}>
                    <div>
                      <input type="text" name="name" className={styles.prop} />
                    </div>
                  </div>
                  <p
                    style={{ fontSize: "13px", marginRight: "15px" }}
                    onClick={() => {
                      onCancel("name")
                    }}
                  >
                    취소하기
                  </p>
                  <p onClick={changeName}>저장하기</p>
                </>
              )}
            </div>
          </div>

          <div>
            <div>연락처</div>
            <div>
              {active.phone === false ? (
                <>
                  <div>{account?.phone}</div>
                  <p onClick={() => setActive({ ...active, ["phone"]: true })}>
                    변경하기
                  </p>
                </>
              ) : (
                <>
                  <div
                    className={`${styles2.box} inputContainer ${styles3.numberInput}`}
                  >
                    <div className={styles3.dropdown} style={{ width: "auto" }}>
                      <button className={styles3.dropbtn} id="phoneButton">
                        010
                      </button>
                      <div className={styles3.dropdowncontent}>
                        <div onClick={dropBoxPw} id="12">
                          12
                        </div>
                        <div onClick={dropBoxPw} id="013">
                          013
                        </div>
                        <div onClick={dropBoxPw} id="032">
                          032
                        </div>
                        <div onClick={dropBoxPw} id="062">
                          062
                        </div>
                      </div>
                    </div>
                    -
                    <input type="text" maxLength="4" name="phonenumber2" />
                    -
                    <input type="text" maxLength="4" name="phonenumber3" />
                  </div>
                  <p
                    style={{ fontSize: "13px", marginRight: "15px" }}
                    onClick={() => {
                      onCancel("phone")
                    }}
                  >
                    취소하기
                  </p>
                  <p onClick={changePhone}>저장하기</p>
                </>
              )}
            </div>
          </div>

          <div>
            <div>주소</div>
            <div>
              {active.address === false ? (
                <>
                  <div>
                    <div>{account?.address}</div>
                  </div>
                  <p
                    onClick={() => setActive({ ...active, ["address"]: true })}
                  >
                    변경하기
                  </p>
                </>
              ) : (
                <>
                  <div className={`${styles2.box} inputContainer`}>
                    <div className={styles.prop2}>
                      <input
                        type="text"
                        name="address"
                        className={styles.prop}
                        style={{ background: "#FAFAFA" }}
                        disabled
                        id="address"
                      />
                      <div className={styles2.btn} onClick={onChangeOpenPost}>
                        주소 입력
                      </div>
                    </div>
                    <div>
                      <input
                        type="text"
                        name="specificAddress"
                        className={styles3.specificAddress}
                        placeholder="상세 주소 입력"
                      />
                    </div>
                  </div>
                  <p
                    style={{ fontSize: "13px", marginRight: "15px" }}
                    onClick={() => {
                      onCancel("address")
                    }}
                  >
                    취소하기
                  </p>
                  <p onClick={changeAddress}>저장하기</p>
                </>
              )}
            </div>
          </div>
        </div>
      </Frame>
    </>
  )
}

export default Subscriber
