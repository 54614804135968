import React from "react"
import * as styles from "./blankModal.module.css"

const BlankModal = props => {
  const containerStyle = {
    width: `${props.size.w}px`,
    height: `${props.size.h}px`,
  }

  return (
    <div className={styles.main} style={containerStyle}>
      <header>{props.title}</header>
      <main>{props.children}</main>
    </div>
  )
}

export default BlankModal
