import React, { useContext, useEffect, useState } from "react"
import Layout from "components/common/layout.js"
import SettingLayout from "components/member/Setting/settingLayout"
import * as styles from "pages/member/setting/settingAccount.module.css"
import Account from "components/member/Setting/account"
import Subscriber from "components/member/Setting/subscriber"
import Others from "components/member/Setting/others"
import modalContext from "context/modalContext"
import TwoBtnModal from "components/tools/modalComponents/twoBtnModal"
import api from "apis"
import { getDateYMD } from "utils"
import { navigate } from "gatsby"
import { setProductListAsync } from "store/modules/payInfoReducer.js"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import { useToggle } from "hooks"
const SettingAccountPage = props => {
  const { setmodalOpen, setModalMain } = useContext(modalContext)
  const [scbInfo, setScbInfo] = useState(null)

  const [flag, toggle] = useToggle()

  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const scbInfo = await api.getscbDetail()
      if (scbInfo) setScbInfo(scbInfo)

      dispatch(setProductListAsync())
    })()
  }, [flag])

  const { list } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  const goods = list.get(scbInfo?.goods_no)

  // 정기구독
  const refundReg = goods?.isReg && !scbInfo?.isSchedule // 다음달이면 구독끝남 => 환불을 요청한 정기구독자
  // 일반 결제
  const refundGen = !goods?.isReg && scbInfo?.isRefund // 환불을 신청한 일반결제자

  const onCancel = async () => {
    if (refundGen) {
      setModalMain(
        <TwoBtnModal
          close={() => setmodalOpen(false)}
          size={{
            w: 542,
            h: 245,
          }}
          onConfirm={async () => {
            const result = await api.scbCloseCancel({
              body: {
                billing_number: scbInfo.billing_no,
              },
            })

            if (!result) {
              alert("구독오류입니다. 관리자에 문의해주세요.")
            }
            toggle()
            setmodalOpen(false)
          }}
          title="구독 해지를 취소하시겠어요?"
          confirm="네"
          cancel="아니요"
        />
      )
      setmodalOpen(true)
    } else {
      navigate(
        goods?.isReg
          ? "/member/setting/UnsubscribeRoutine"
          : "/member/setting/UnsubscribePlan"
      )
    }
  }

  return (
    <Layout settingHeader headerStyle={{ display: "none" }}>
      <SettingLayout title="회원정보">
        <div className={styles.subs}>
          {scbInfo?.isScb ? (
            <>
              <div>
                {getDateYMD(scbInfo.begin, "YYYY년 MM월 DD일")}에 구독을
                시작하셨습니다.
              </div>

              {refundReg ? null : (
                <div
                  className={"confirmBtn"}
                  style={{ width: "180px", fontSize: "14px" }}
                  onClick={onCancel}
                >
                  {refundGen ? "구독 해지 해제" : "구독 해지"}
                </div>
              )}
            </>
          ) : null}
        </div>
      </SettingLayout>

      <Account />
      {scbInfo?.isProfile ? (
        <Subscriber subscriber={scbInfo} /> // 기존엔 프로파일만넘겼음
      ) : null}
      <Others goods={goods} scbInfo={scbInfo} />
    </Layout>
  )
}

export default SettingAccountPage
