import React, { useEffect, useState } from "react"
import Frame from "./frame"
import * as styles from "components/member/Setting/account.module.css"
import Password from "components/member/signUp/password.js"
import MobileCheck from "components/tools/mobileCheck.js"
import * as styles2 from "components/tools/mobileCheck.module.css"
import api from "apis"
import { vaild, onPwdCheck } from "utils/memberUtils"
import cookie from "react-cookies"
import { getJson, getExpire } from "utils"
const Account = props => {
  const [active, setActive] = useState({
    name: false,
    password: false,
    phone: false,
    email: false,
  })

  const [account, setAccount] = useState({})
  const [pass, setPassword] = useState(false)
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    ;(async () => {
      const post = await api.setting("account")
      setAccount(post.account)
    })()
  }, [active, pass, mobile])

  useEffect(() => {
    if (mobile && active.name) {
      const reviseName = document.querySelector("#reviseName")
      reviseName.disabled = false
      reviseName.backgroundColor = "#FFFFFF"
    }
  }, [active.name, mobile])

  const name = async () => {
    console.log(mobile)
    console.log(document.querySelector("#reviseName").value)

    if (mobile) {
      const account_name = document.querySelector("#reviseName").value
      await api.reviseSetting("reviseAccountName", {
        body: {
          account_name,
        },
      })

      const access = getJson("access_token")
      const option = getExpire("expires")
      cookie.save(
        "access_token",
        {
          ...access,
          name: account_name,
        },
        option
      )

      setMobile(false)
      setActive({ ...active, ["name"]: false })
    }
  }

  const password = async () => {
    onPwdCheck()
    if (!vaild.pwdMain || !vaild.pwdSub) {
      // setActive({ ...active, ["password"]: false })
    } else {
      const post = await api.reviseSetting("reviseAccountPassword", {
        body: {
          account_password:
            document.querySelectorAll("input[name=pwd]")[0].value,
        },
      })
      if (post.desc == "success") {
        setActive({ ...active, ["password"]: false })
      }
    }
  }

  const phone = async () => {
    console.log(mobile)

    if (mobile) {
      const post = await api.reviseSetting(
        "reviseAccountPhone",

        {
          body: {
            phone_uuid: mobile,
          },
        }
      )

      if (post.desc == "success") {
        setActive({ ...active, ["phone"]: false })
      }
    } else {
      setActive({ ...active, ["phone"]: false })
    }
  }

  const email = async () => {
    // console.log(document.querySelectorAll('input[name=email]')[0].value)

    if (document.querySelectorAll("input[name=email]")[0].value) {
      const post = await api.reviseSetting(
        "reviseAccountEmail",

        {
          body: {
            account_email:
              document.querySelectorAll("input[name=email]")[0].value,
          },
        }
      )

      // console.log(post.desc)
      if (post.desc == "success") {
        setActive({ ...active, ["email"]: false })
      }
    } else {
      setActive({ ...active, ["email"]: false })
    }
  }

  const onCancel = target => {
    setActive({ ...active, [target]: false })
  }

  return (
    <>
      <Frame title="계정 정보">
        <div className={styles.con}>
          <div>
            <div>아이디</div>
            <div>{account.id}</div>
          </div>

          <div>
            <div>이름</div>
            <div
              className={`${styles2.box} ${
                active.name !== false ? `inputContainer` : ``
              }`}
            >
              {active.name === false ? (
                <>
                  <div>{account.name}</div>
                  <p
                    style={{ fontSize: "13px" }}
                    onClick={() => setActive({ ...active, ["name"]: true })}
                  >
                    변경하기
                  </p>
                </>
              ) : (
                <>
                  <MobileCheck
                    className={styles.prop}
                    divClassName={styles.prop2}
                    setting={true}
                    setMobile={setMobile}
                  />
                  <div className={`${styles2.box} inputContainer`}>
                    <div className={styles.prop2} style={{ placeSelf: "auto" }}>
                      <input
                        id="reviseName"
                        type="text"
                        name="name"
                        className={styles.prop}
                        disabled={true}
                        style={{ backgroundColor: "#FAFAFA" }}
                      />
                      <div style={{ display: "flex", alignItems: "flex-end" }}>
                        <p
                          className={styles.btn}
                          style={{ fontSize: "13px", marginRight: "15px" }}
                          onClick={() => {
                            onCancel("name")
                          }}
                        >
                          취소하기
                        </p>
                        <p
                          className={styles.btn}
                          style={{ fontSize: "13px" }}
                          onClick={name}
                        >
                          저장하기
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div>
            <div>비밀번호</div>
            <div>
              {active.password === false ? (
                <>
                  <div>*************</div>
                  <p
                    onClick={() => setActive({ ...active, ["password"]: true })}
                  >
                    변경하기
                  </p>
                </>
              ) : (
                <>
                  <Password />
                  <p
                    style={{ fontSize: "13px", marginRight: "15px" }}
                    onClick={() => {
                      onCancel("password")
                    }}
                  >
                    취소하기
                  </p>
                  <p onClick={password}>저장하기</p>
                </>
              )}
            </div>
          </div>

          <div>
            <div>휴대폰</div>
            <div>
              {active.phone === false ? (
                <>
                  <div>{account.phone}</div>
                  <p onClick={() => setActive({ ...active, ["phone"]: true })}>
                    변경하기
                  </p>
                </>
              ) : (
                <>
                  <MobileCheck
                    className={styles.prop}
                    divClassName={styles.prop2}
                    setting={true}
                    setMobile={setMobile}
                  />
                  <p
                    style={{ fontSize: "13px", marginRight: "15px" }}
                    onClick={() => {
                      onCancel("phone")
                    }}
                  >
                    취소하기
                  </p>
                  <p onClick={phone}>저장하기</p>
                </>
              )}
            </div>
          </div>

          <div>
            <div>이메일</div>
            <div>
              {active.email === false ? (
                <>
                  <div>{account.email}</div>
                  <p onClick={() => setActive({ ...active, ["email"]: true })}>
                    변경하기
                  </p>
                </>
              ) : (
                <>
                  <div className={`${styles2.box} inputContainer`}>
                    <div className={styles.prop2}>
                      <input
                        type="text"
                        placeholder="이메일 주소 입력"
                        name="email"
                        className={styles.prop}
                      />
                      {/* <div className={styles2.btn}>인증번호 받기</div> */}
                    </div>
                    {/* <div className={styles.prop2}>
                      <input
                        type="password"
                        placeholder="인증번호 입력"
                        name="mobile"
                        className={styles.prop}
                      />
                      <div className="greybtn">확인</div>
                    </div> */}
                    <div>
                      <div className="errMsg" name="email"></div>
                    </div>
                  </div>
                  <p
                    style={{ fontSize: "13px", marginRight: "15px" }}
                    onClick={() => {
                      onCancel("email")
                    }}
                  >
                    취소하기
                  </p>
                  <p onClick={email}>저장하기</p>
                </>
              )}
            </div>
          </div>
        </div>
      </Frame>
    </>
  )
}

export default Account
