import React from "react"
import * as styles from "components/member/Setting/settingLayout.module.css"
import * as styles2 from "pages/member/setting/settingAccount.module.css"
import * as styles3 from "components/member/Setting/frame.module.css"

const Frame = props => {
  return (
    <>
      <div className={styles.main2}>
        <div className={styles.main}>
          <div style={{ fontSize: "20px" }}>
            {props.title == "" ? null : <div className={styles3.colon}>
              <div>.</div>
              <div>.</div>
            </div>}

            {props.title}
          </div>
          <div className={styles2.subs2}>{props.children}</div>
        </div>
        {props.title == "" ? null : <hr></hr>}
      </div>
    </>
  )
}

export default Frame
