import React, { useEffect, useState } from "react"
import Frame from "./frame"
import * as styles from "components/member/Setting/account.module.css"
import { navigate } from "gatsby"
import { payMethodTable } from "utils/data.js"
import { getDateYMD } from "utils"
import api from "apis"
const Others = props => {
  console.log(9598, props)
  const { scbInfo, goods } = props

  console.log(scbInfo)
  const [method, setMethod] = useState(null)

  useEffect(() => {
    if (scbInfo?.billing_no) {
      ;(async () => {
        const method = await api.billingMethod(scbInfo.billing_no)
        setMethod(method)
      })()
    }
  }, [scbInfo])

  const [coupon, setCoupon] = useState("")
  useEffect(() => {
    ;(async () => {
      const result = await api.getCoupon()
      setCoupon(result)
      console.log(result)
    })()
  }, [])
  console.log(goods)
  return (
    <div style={{ marginBottom: "183.7px" }}>
      {scbInfo?.isScb || scbInfo?.isExpired ? (
        <>
          <Frame title="구독 정보">
            <div className={styles.con}>
              <div>
                <div>구독상품</div>
                <div>
                  <div>
                    {scbInfo?.isExpired
                      ? "구독중인 상품이 없습니다."
                      : `${goods?.title}`}
                  </div>
                </div>
              </div>

              {scbInfo?.isExpired ? null : (
                <div>
                  <div>구독기간</div>
                  <div>
                    <div>
                      {getDateYMD(scbInfo.begin, "YYYY년 MM월 DD일")}~
                      {getDateYMD(scbInfo.end, "YYYY년 MM월 DD일")}
                    </div>
                  </div>
                </div>
              )}
              {goods?.isReg && !scbInfo?.isSchedule ? (
                <div>
                  <div />
                  <div
                    style={{
                      color: "#7C7C7C",
                      fontSize: "14px",
                    }}
                  >
                    {getDateYMD(scbInfo.end, "YYYY년 MM월 DD일")} 이후
                    정기구독이 해지됩니다.
                  </div>
                </div>
              ) : null}
              <div>
                <div></div>
                <div>
                  <div></div>
                  <p></p>
                </div>
              </div>
            </div>
          </Frame>
          {scbInfo.owner ? (
            <Frame title="결제 정보">
              <div className={styles.con}>
                <div>
                  <div>결제수단</div>
                  <div>
                    {scbInfo?.isExpired ? (
                      <div>등록된 결제수단이 없습니다.</div>
                    ) : (
                      <div>
                        {payMethodTable.get(method?.billing_method)}
                        {method?.method_memo ? ` (${method.method_memo})` : ""}
                      </div>
                    )}
                  </div>
                </div>

                <div>
                  <div></div>
                  <div>
                    <div></div>
                    <p
                      onClick={() =>
                        navigate("/member/setting/PaymentHistory/")
                      }
                    >
                      결제 기록 보기
                    </p>
                  </div>
                </div>
              </div>
            </Frame>
          ) : null}
        </>
      ) : null}

      <Frame title="쿠폰">
        <div className={styles.con}>
          <div>
            <div>보유 쿠폰 {coupon.length}개</div>
            <div>
              <div></div>
              <p onClick={() => navigate("/member/setting/Coupon/")}>
                보유 쿠폰 보기
              </p>
            </div>
          </div>
        </div>
      </Frame>
    </div>
  )
}

export default Others
